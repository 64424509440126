import { useState } from "react";

import { Tabs, TabsContent } from "@/components/ui/tabs";

function HowSecondLabWorks() {
  const [currentTab, setCurrentTab] = useState("researcher");

  return (
    <>
      <div className="responsive-spacing pt-14 md:pt-[124px]">
        <h2 className="mb-6 text-center text-emerald-900">
          How Second Lab Works
        </h2>
        <div className="text-center">
          <Tabs className="" value={currentTab}>
            <div className="mx-4 mb-8 overflow-x-auto md:mx-auto md:mb-10 md:max-w-[828px]">
              <div className="grid min-w-[800px] grid-cols-3">
                <h2
                  className={`${currentTab === "researcher" ? "p2-bold border-b-4 border-b-gold-400 text-interface-black" : "p2 border-gold-100 text-emerald-900"} h-9 cursor-pointer border-b md:h-12`}
                  onClick={() => setCurrentTab("researcher")}
                >
                  For Research Teams
                </h2>
                <h2
                  className={`${currentTab === "university" ? "p2-bold border-b-4 border-b-gold-400 text-interface-black" : "p2 border-gold-100 text-emerald-900"} h-9 cursor-pointer border-b md:h-12`}
                  onClick={() => setCurrentTab("university")}
                >
                  For University Labs
                </h2>
                <h2
                  className={`${currentTab === "administrators" ? "p2-bold border-b-4 border-b-gold-400 text-interface-black" : "p2 border-gold-100 text-emerald-900"} h-9 cursor-pointer border-b md:h-12`}
                  onClick={() => setCurrentTab("administrators")}
                >
                  For University Administrators
                </h2>
              </div>
            </div>

            <TabsContent value="researcher">
              <div className="grid grid-cols-1 items-center md:grid-cols-2 md:gap-16">
                <img
                  src="/researcher.png"
                  alt="researcher"
                  className="h-[347px] w-full rounded-lg object-cover"
                />
                <div className="mt-6 flex flex-col md:mt-0">
                  <h3 className="mb-6 text-left">
                    Reach your milestones faster. Unprecedented, on-demand
                    access to core labs.
                  </h3>
                  <div className="p2 text-left text-emerald-900">
                    Quickly book university instruments, processing services,
                    and scientific analysis at the best core facilities in the
                    world. Signup is fast and easy, with new contracts usually
                    approved in under 48 hours. Business-friendly terms,
                    carefully negotiated to safeguard your intellectual property
                    and valuable trade secrets - with simple, secure payment and
                    data transmission solutions to accelerate your research
                    workflows.
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="university">
              <div className="grid grid-cols-1 items-center md:grid-cols-2 md:gap-16">
                <img
                  src="/uni-lab.png"
                  alt="University Labs"
                  className="h-[347px] w-full rounded-lg object-cover"
                />
                <div className="mt-6 flex flex-col md:mt-0">
                  <h3 className="mb-6 text-left">
                    Born at UC Berkeley. Forged by leading scientists.
                  </h3>
                  <div className="p2 text-left text-emerald-900">
                    Co-designed with UC Berkeley, Second Lab partners with
                    leading US research institutions to streamline external
                    access to excess lab capacity. Engage and collaborate with
                    exciting new companies to do groundbreaking research.
                    Convert idle instrument time into stable unrestricted
                    revenue. Leverage our outstanding onshore support team to
                    eliminate time consuming administrative headaches. And
                    quickly create a thriving ecosystem of science and
                    technology companies that rely on your facilities to move
                    the world forward.
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="administrators">
              <div className="grid grid-cols-1 items-center md:grid-cols-2 md:gap-16">
                <img
                  src="/uni-admin.png"
                  alt="University Administrators"
                  className="h-[347px] w-full rounded-lg object-cover"
                />
                <div className="mt-6 flex flex-col md:mt-0">
                  <h3 className="mb-6 text-left">
                    Train, certify, and communicate with confidence.
                  </h3>
                  <div className="p2 text-left text-emerald-900">
                    Second Lab helps safeguard university compliance by
                    streamlining the safety, training, payment, and access
                    procedures for each individual facility. Create custom
                    workflows and check-in requirements for each individual
                    machine, or search through our robust content library for
                    ideas. Share lab etiquette expectations, preparation
                    instructions, and cleanup checklists. Manage external access
                    in real time: grant, limit, or revoke as needed. And
                    communicate easily and clearly across hundreds of customer
                    relationships.
                  </div>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default HowSecondLabWorks;
